import { Button as ShadButton } from "@/components/ui/button.tsx";
import { useState } from "react";
import { UserProfileDialog } from "@/components/UserProfileDialog";

export const ManageAccount = ({
  sidebarCollapsed = false,
}: {
  sidebarCollapsed?: boolean;
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <div className={"flex justify-start items-center gap-[1rem]"}>
      <div className={"flex w-[2.5rem] h-[2.5rem]"}>
        <img
          alt={""}
          onClick={() => {
            setModalOpen(true);
          }}
          src={"/bg_gradient.jpg"}
          className={
            "rounded-full cursor-pointer shrink-0 bg-[#A259FF] w-[2.5rem] h-[2.5rem]"
          }
        />
      </div>
      {!sidebarCollapsed && (
        <div className={"hidden md:block md:ml-2"}>
          <ShadButton
            className={"p-0 h-auto text-black font-medium"}
            variant={"link"}
            onClick={() => {
              setModalOpen(true);
            }}
          >
            <span className={"text-black"}>My Account</span>
          </ShadButton>
          {modalOpen && (
            <UserProfileDialog
              open={modalOpen}
              onOpenChange={() => setModalOpen(false)}
            />
          )}
        </div>
      )}
    </div>
  );
};
