import { createFileRoute } from "@tanstack/react-router";
import { Ad, DiscoverGridView } from "@/components/templates/DiscoverGridView";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { Loader } from "@/components/custom-components/Loader";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { Text } from "@/components/custom-components";
import { Divider } from "@/components/custom-components/Divider";
import { SidebarButton } from "@/components/templates/Sidebar";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/testing-labs/")({
  component: All,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function All() {
  const { sideBarOpen } = Route.useSearch();
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);
  const [allData, setAllData] = useState<Ad[] | undefined>(undefined);

  const { data, fetchNextPage, isLoading, isError, isRefetching } =
    trpc.filterAdFeed.useInfiniteQuery(
      { timeLive: ["testing_lab"] },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

  const { data: permissionData, isLoading: isLoadingPermission } =
    trpc.permissions.useQuery(null, {});

  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(() => {
    if (!isLoadingPermission && !permissionData?.userCanAccessEverything) {
      setUpgradeDialogOpen(true);
    }
  }, [
    permissionData,
    permissionData?.userCanAccessEverything,
    isLoadingPermission,
  ]);

  useEffect(() => {
    if (!inView || !data || !allData || !allData.length) return;
    if (data.pages[data.pages.length - 1].ads.length === 0) {
      return;
    }
    fetchNextPage();
  }, [inView, data, allData, fetchNextPage]);

  useEffect(() => {
    if (!data) return;

    setAllData(() => undefined);
    const records = [] as Ad[];
    for (const page of data.pages) {
      records.push(...page.ads);
    }
    setAllData(() => records);
  }, [data]);

  if (isError) {
    return (
      <div className="px-10">
        <ErrorDisplay />
      </div>
    );
  }
  return (
    <>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      <div className={"px-5 md:px-5"}>
        <div className={"sticky top-0 z-10"}>
          <div className={"bg-white pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div
              className={
                "flex justify-between lg:justify-start gap-5 items-center"
              }
            >
              <div
                className={
                  "flex flex-col justify-start items-center lg:flex-row lg:gap-10"
                }
              >
                <Text
                  size={"lg"}
                  weight={"semibold"}
                  className={
                    "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                  }
                >
                  Testing Labs
                </Text>
                <p
                  className={
                    "text-[#7F7F7F] font-medium text-[0.875rem] text-nowrap text-left"
                  }
                >
                  {"Ads live for < 7 days"}
                </p>
              </div>
              <SidebarButton sideBarOpen={sideBarOpen} />
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
          </div>
        </div>
        <div className="px-0 md:px-0">
          {!allData || isLoading || isRefetching ? (
            <div className="flex justify-center items-center w-full h-screen">
              <Loader />
            </div>
          ) : (
            <div>
              {allData.length === 0 ? (
                <div className={"flex justify-center items-center"}>
                  <p className={"text-center w-4/5 lg:w-1/2"}>
                    Oops! It looks like there are no ad inspirations matching
                    your filtered options right now. Please check back later for
                    updates.
                  </p>
                </div>
              ) : (
                !isLoadingPermission &&
                permissionData?.userCanAccessEverything && (
                  <DiscoverGridView data={allData} scrollRef={scrollRef} />
                )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default All;
