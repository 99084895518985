"use client";

import * as React from "react";
import * as RadioGroupPrimitive from "@radix-ui/react-radio-group";

import { cn } from "@/lib/utils";
import { Circle } from "lucide-react";

const RadioGroup = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Root>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Root
      className={cn("grid gap-2", className)}
      {...props}
      ref={ref}
    />
  );
});
RadioGroup.displayName = RadioGroupPrimitive.Root.displayName;

const StandardRadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, ...props }, ref) => {
  return (
    <RadioGroupPrimitive.Item
      ref={ref}
      className={cn(
        "aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
        className,
      )}
      {...props}
    >
      <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
        <Circle className="h-2.5 w-2.5 fill-current text-current" />
      </RadioGroupPrimitive.Indicator>
    </RadioGroupPrimitive.Item>
  );
});
StandardRadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

const RadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item> & {
    plan: {
      name: string;
      price: string;
      plan_id: string;
      description: string;
    };
    showTrial?: boolean;
  }
>(({ className, checked, id, plan, showTrial = true, ...props }, ref) => {
  return (
    <label
      htmlFor={id}
      className={cn(
        "bg-white min-h-[5rem] flex flex-col justify-start rounded-lg border-2 p-4",
        checked ? "border-themedestructive" : "border-themeinput",
        className,
      )}
    >
      <div className="flex items-center justify-between space-x-3">
        <div className="flex items-center space-x-2">
          <RadioGroupPrimitive.Item
            ref={ref}
            id={id}
            className={cn(
              "aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
              "data-[state=checked]:border-themedestructive data-[state=checked]:text-themedestructive",
              className,
            )}
            {...props}
          >
            <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
              <Circle className="h-2.5 w-2.5 fill-current text-current" />
            </RadioGroupPrimitive.Indicator>
          </RadioGroupPrimitive.Item>
          <span className={"font-medium"}>{plan.name}</span>
          {showTrial && (
            <span
              className={
                "rounded-full px-3 py-1 bg-purple-100 text-themedestructive text-xs font-medium text-nowrap"
              }
            >
              3-Day Trial
            </span>
          )}
        </div>
        <div>
          <span className={"font-bold"}>{plan.price.slice(0, -3)}</span>
        </div>
      </div>
      <div className={"mt-1.5"}>
        <p className={"font-light text-sm"}>{plan.description}</p>
      </div>
    </label>
  );
});
RadioGroupItem.displayName = RadioGroupPrimitive.Item.displayName;

const OnboardingRadioGroupItem = React.forwardRef<
  React.ElementRef<typeof RadioGroupPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof RadioGroupPrimitive.Item>
>(({ className, checked, id, ...props }, ref) => {
  return (
    <label
      htmlFor={id}
      className={cn(
        "bg-white flex flex-col justify-start rounded-lg border-2 p-4",
        checked ? "border-themedestructive" : "border-themeinput",
        className,
      )}
    >
      <span className="flex items-center space-x-3 flex-1">
        <RadioGroupPrimitive.Item
          ref={ref}
          id={id}
          className={cn(
            "aspect-square h-4 w-4 rounded-full border border-primary text-primary ring-offset-background focus:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50",
            "data-[state=checked]:border-themedestructive data-[state=checked]:text-themedestructive",
            className,
          )}
          {...props}
        >
          <RadioGroupPrimitive.Indicator className="flex items-center justify-center">
            <Circle className="h-2.5 w-2.5 fill-current text-current" />
          </RadioGroupPrimitive.Indicator>
        </RadioGroupPrimitive.Item>
        <span className="font-medium">{id}</span>
      </span>
    </label>
  );
});
OnboardingRadioGroupItem.displayName = "OnboardingRadioGroupItem";

export {
  RadioGroup,
  RadioGroupItem,
  OnboardingRadioGroupItem,
  StandardRadioGroupItem,
};
