import {
  Dialog,
  DialogContent,
  DialogHeader,
} from "@/components/shadcn/dialog.tsx";
import { Button } from "@/components/shadcn/button.tsx";
import { useEffect, useState } from "react";
import { plans, PRO_PLAN, STANDARD_PLAN } from "@/utils/data/plans.ts";
import UpgradeBox from "@/components/UserProfileDialog.tsx";
import { trpc } from "@/utils/trpc.ts";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
} from "@/components/shadcn/form.tsx";
import { RadioGroup, RadioGroupItem } from "@/components/shadcn/radio.tsx";
import { z } from "zod";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { useNavigate } from "@tanstack/react-router";
import { CheckCircle } from "@mynaui/icons-react";
import { PermissionsData } from "@/routes/feeds.tsx";

export default function FreeTrialEnding({
  open,
  onOpenChange,
  permissionData,
}: {
  open: boolean;
  onOpenChange: () => void;
  permissionData: PermissionsData;
}) {
  const [subscriptionStatus, setSubscriptionStatus] = useState<
    "onFreeTrial" | "hasPlan" | "hasNoPlan" | undefined
  >(undefined);
  const [selectedPlan, setSelectedPlan] = useState<string | undefined>(
    undefined,
  );

  const { data: chargebeeSyncStatus } = trpc.getIsUserSynced.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    },
  );

  const { mutate: openPortalSessionUrl, isPending: fetchingPortalSessionUrl } =
    trpc.getPortalSessionUrl.useMutation({
      onSuccess: async (data) => {
        if (data) {
          window.location.href = data;
        }
      },
    });

  const navigate = useNavigate();

  useEffect(() => {
    if (permissionData && plans) {
      const defaultPlan = plans[1].plan_id;

      if (
        permissionData.userCanAccessEverything &&
        permissionData.usersPlans.length === 0
      )
        setSubscriptionStatus("onFreeTrial");
      else if (
        permissionData.usersPlans.length > 0 &&
        permissionData.usersPlans.some((plan) =>
          [STANDARD_PLAN.plan_id, PRO_PLAN.plan_id].includes(plan),
        )
      ) {
        setSubscriptionStatus("hasPlan");
      } else {
        setSubscriptionStatus("hasNoPlan");
        form.setValue("plan", defaultPlan);
        setSelectedPlan(defaultPlan);
      }
    }
  }, [permissionData]);

  const selectPlanFormSchema = z.object({
    plan: z.string({ required_error: "Select a plan" }),
  });

  type SelectPlanFormValues = z.infer<typeof selectPlanFormSchema>;

  const defaultValues: Partial<SelectPlanFormValues> = {};

  const form = useForm<SelectPlanFormValues>({
    resolver: zodResolver(selectPlanFormSchema),
    defaultValues,
  });

  async function onSubmit() {
    const selectedPlan = form.getValues("plan");

    if (chargebeeSyncStatus) {
      openPortalSessionUrl();
    } else if (selectedPlan) {
      localStorage.setItem("cos_plan", selectedPlan); // Store selected plan in localStorage
      setTimeout(() => {
        navigate({ to: "/checkout" });
      }, 500);
    }
  }

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <div>
        <DialogContent
          className={`sm:max-w-lg ${subscriptionStatus === "hasNoPlan" ? "[&>button]:hidden" : ""}`}
          onInteractOutside={(e) => e.preventDefault()}
        >
          <DialogHeader>
            <div className={"flex flex-col gap-2"}>
              <h4
                className={
                  "text-2xl text-left text-themeforeground font-semibold"
                }
              >
                Upgrade to Unlock
              </h4>
              <p className={"text-left text-thememutedforeground"}>
                Level up your ad workflow with our paid plans
              </p>
            </div>
          </DialogHeader>
          <div>
            <>
              {subscriptionStatus === "hasNoPlan" && (
                <div className={"space-y-3 mb-6"}>
                  {plans &&
                    plans
                      .find((item) => item.plan_id === selectedPlan)
                      ?.features.map((feature, index) => (
                        <div
                          key={index}
                          className="flex gap-1 justify-start items-center text-thememutedforeground"
                        >
                          <CheckCircle className="w-4 h-4" />
                          <span className="text-sm font-normal leading-5 ">
                            {feature}
                          </span>
                        </div>
                      ))}
                </div>
              )}

              {subscriptionStatus === "hasPlan" &&
                permissionData.usersPlans.find(
                  (p) => p === STANDARD_PLAN.plan_id,
                ) && (
                  <div className={"space-y-3 mb-6"}>
                    {PRO_PLAN.features.map((feature, index) => (
                      <div
                        key={index}
                        className="flex gap-1 justify-start items-center text-thememutedforeground"
                      >
                        <CheckCircle className="w-4 h-4" />
                        <span className="text-sm font-normal leading-5 ">
                          {feature}
                        </span>
                      </div>
                    ))}
                  </div>
                )}
            </>
          </div>
          <div className="gap-3 flex flex-col">
            <h5>Select your plan</h5>
            {subscriptionStatus === "hasPlan" ? (
              <div className="gap-3 flex flex-col">
                {plans &&
                  plans.map((plan, index) => (
                    <UpgradeBox
                      boxData={plan}
                      key={index}
                      userPlans={permissionData?.usersPlans ?? []}
                      descriptionDetailsToShow={"description"}
                    />
                  ))}
              </div>
            ) : (
              <div>
                <Form {...form}>
                  <form
                    onSubmit={form.handleSubmit(onSubmit)}
                    className="space-y-6"
                  >
                    <div className={"space-y-6"}>
                      <FormField
                        control={form.control}
                        name="plan"
                        render={({ field }) => (
                          <RadioGroup
                            onValueChange={field.onChange}
                            value={field.value}
                            className="flex flex-col space-y-1"
                          >
                            {plans &&
                              plans.map((item) => (
                                <FormControl key={item.plan_id}>
                                  <FormItem>
                                    <RadioGroupItem
                                      checked={field.value === item.plan_id}
                                      value={item.plan_id}
                                      onChange={() =>
                                        field.onChange(item.plan_id)
                                      }
                                      id={item.name}
                                      plan={item}
                                      showTrial={false}
                                      onClick={() =>
                                        setSelectedPlan(item.plan_id)
                                      }
                                    >
                                      {item.name}
                                    </RadioGroupItem>
                                  </FormItem>
                                </FormControl>
                              ))}
                          </RadioGroup>
                        )}
                      />
                      <Button
                        className={"w-full"}
                        variant={"destructivePurple"}
                        size={"lg"}
                        disabled={fetchingPortalSessionUrl}
                        type={"submit"}
                      >
                        Upgrade
                      </Button>
                    </div>
                  </form>
                </Form>
              </div>
            )}
          </div>

          <div className={"space-y-3"}>
            <div className={"flex justify-center"}>
              <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
                <span className={"underline font-medium text-sm text-center"}>
                  View full plan comparison
                </span>
              </a>
            </div>
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
}
