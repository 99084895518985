import { useEffect, useRef, useState } from "react";
import { useElementSize } from "@/hooks/useElementSize.tsx";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { DiscoverAdCard } from "@/components/templates/AdCard";
import { z } from "zod";
import { useNavigate } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { GetAdArgs } from "../../../../../server/rpc";
import { Loader } from "@/components/custom-components/Loader";

export type Ad = {
  id: number;
  reach: number | null;
  earliestView: string;
  latestLaunch: string;
  adRunningDays: number;
  imageUrl: string | null;
  brandId: string | null;
  brandName: string | null;
  brandImage: string | null;
  videoUrl: string | null;
  hasUserSeenAd?: boolean;
  isActiveSinceLastScrape?: boolean;
  isSaved: boolean;
  rank?: number;
  inactiveTime?: number;
};

export type Brand = {
  brandName: string;
  brandImage: string;
  ads: Ad[];
};

const BATCH_DELAY = 600000; // Delay in milliseconds - 10 minutes
const MAX_BATCH_SIZE = 10; // Maximum number of posts to batch

export const DiscoverGridView = ({
  data,
  scrollRef,
  isRefetching,
}: {
  isRefetching?: boolean;
  data: Ad[];
  scrollRef?: (node?: Element | null | undefined) => void;
}) => {
  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const [columns, setColumns] = useState<number | undefined>(undefined);
  const [squareRef, { width = 0 }] = useElementSize();
  const breakpoints = {
    600: 1,
    750: 2,
    900: 3,
    1200: 4,
  };
  const gutterWidth = 18;

  useEffect(() => {
    if (!width) {
      return;
    }
    if (width > 1200) {
      // width = 1400
      // desired = (1400 / 4) - (18 * 3)

      const newWidth =
        width / breakpoints[1200] - gutterWidth * (breakpoints[1200] - 1);
      setColumns(() => 4);
      setDesiredCardWidth(() => newWidth);
      return;
    }
    if (width > 900) {
      const newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 3);
      return;
    }
    if (width > 750) {
      const newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 2);
      return;
    }
    // Added this condition for rendering two columns for board collection preview
    if (width > 400) {
      const newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 2);
      return;
    }
    if (width > 0) {
      const newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 1);
      return;
    }
  }, [width]);

  const { mutate: recordImpressions } = trpc.recordImpressions.useMutation();

  const pendingImpressions = useRef<Set<number>>(new Set());
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const addPostToBatch = (postId: number) => {
    if (!pendingImpressions.current.has(postId)) {
      pendingImpressions.current.add(postId);

      if (pendingImpressions.current.size >= MAX_BATCH_SIZE) {
        // If the batch is full, send immediately
        sendBatch();
      } else {
        // Start or reset the timer if it's not already running
        if (!timerRef.current) {
          timerRef.current = setTimeout(sendBatch, BATCH_DELAY);
        }
      }
    }
  };

  const sendBatch = () => {
    if (pendingImpressions.current.size > 0) {
      const postIdsArray = Array.from(pendingImpressions.current);
      recordImpressions(
        { ads: postIdsArray },
        {
          onSuccess: () => {
            pendingImpressions.current.clear();
          },
          onError: (error) => {
            console.error("Error recording impressions:", error);
          },
        },
      );
      // Clear the timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }
  };

  return (
    <div>
      <div ref={squareRef} className={"relative w-full pb-10"}>
        {data && (
          <div>
            <ResponsiveMasonry
              columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
            >
              <Masonry gutter={gutterWidth + "px"}>
                {data &&
                  data.map((ad) => {
                    return (
                      <DiscoverAdCard
                        key={ad.id}
                        DesiredWidth={desiredCardWidth}
                        adData={ad}
                        IsPublic={false}
                        IsBlurred={false}
                        onView={addPostToBatch}
                      />
                    );
                  })}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        )}
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          >
            {isRefetching && (
              <div
                className={
                  "z-10 flex justify-center items-center w-full m-auto"
                }
              >
                <Loader />
              </div>
            )}
          </div>
        </div>
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          >
            {isRefetching && (
              <div
                className={
                  "z-10 flex justify-center items-center w-full m-auto"
                }
              >
                <Loader />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

type publicProps = z.infer<typeof GetAdArgs>;

export const DiscoverGridViewPublic = (props: publicProps) => {
  // If a user is logged in, they don't need to see this screen
  const { data: authUser } = trpc.me.useQuery(null);

  const navigate = useNavigate();
  if (authUser) {
    navigate({
      to: "/feeds/inspiration/$adID",
      params: {
        adID: props.adId.toString(),
      },
    });
  }

  // record impressions

  const { mutate: recordImpressions } = trpc.recordImpressions.useMutation();

  const pendingImpressions = useRef<Set<number>>(new Set());
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const addPostToBatch = (postId: number) => {
    if (pendingImpressions.current.entries.length >= 50) {
      return; // limit number impressions to be added
    }
    if (!pendingImpressions.current.has(postId)) {
      pendingImpressions.current.add(postId);
      if (!timerRef.current) {
        timerRef.current = setTimeout(sendBatch, BATCH_DELAY);
      }
    }
  };

  const sendBatch = () => {
    if (pendingImpressions.current.size > 0) {
      const postIdsArray = Array.from(pendingImpressions.current);
      recordImpressions(
        { ads: postIdsArray },
        {
          onSuccess: () => {
            pendingImpressions.current.clear();
          },
          onError: (error) => {
            console.error("Error recording impressions:", error);
          },
        },
      );
      // Clear the timer
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
    }
  };

  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const [columns, setColumns] = useState<number | undefined>(undefined);
  const [squareRef, { width = 0 }] = useElementSize();
  const breakpoints = {
    600: 1,
    750: 2,
    900: 3,
    1200: 4,
  };
  const gutterWidth = 18;
  useEffect(() => {
    if (!width) {
      return;
    }
    if (width > 1200) {
      // width = 1400
      // desired = (1400 / 4) - (18 * 3)

      const newWidth =
        width / breakpoints[1200] - gutterWidth * (breakpoints[1200] - 1);
      setColumns(() => 4);
      setDesiredCardWidth(() => newWidth);
      return;
    }
    if (width > 900) {
      const newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 3);
      return;
    }
    if (width > 750) {
      const newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 2);
      return;
    }
    if (width > 0) {
      const newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 1);
      return;
    }
  }, [width]);

  const { data, isError, isLoading, isRefetching } =
    trpc.previewAdInFeed.useQuery(
      {
        adId: props.adId,
      },
      {
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!data || isLoading || isRefetching) {
    return (
      <div
        className={"flex w-full justify-center items-center h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div ref={squareRef} className={"relative w-full px-0 md:px-0 pb-10"}>
      {data && (
        <div>
          <ResponsiveMasonry
            columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
          >
            <Masonry gutter={gutterWidth + "px"}>
              {data.map((ad, index) => {
                return (
                  <DiscoverAdCard
                    key={ad.id}
                    DesiredWidth={desiredCardWidth}
                    adData={ad}
                    IsPublic={true}
                    IsBlurred={index !== 0}
                    onView={addPostToBatch}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      )}
    </div>
  );
};
