import { createFileRoute, Link } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { ErrorDisplay } from "@/components/error.tsx";
import { Button, Text } from "@/components/custom-components";
import { Loader } from "@/components/custom-components/Loader";
import { useInView } from "react-intersection-observer";
import { useEffect, useState } from "react";
import { Divider } from "@/components/custom-components/Divider";
import { toast } from "sonner";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AdCard } from "@/components/templates/AdCard";
import { useElementSize } from "@/hooks/useElementSize.tsx";
import { AirTableAdRecord } from "../../../shared/airtableGet.ts";
import { SidebarButton } from "@/components/templates/Sidebar";
import { ShareIcon } from "lucide-react";

type SearchParams = {
  sideBarOpen?: boolean;
};

export const Route = createFileRoute("/feeds/collections/$collectionID")({
  component: CollectionTemplates,
  validateSearch: (search: Record<string, unknown>): SearchParams => {
    const sideBarOpen = search?.sideBarOpen as boolean;

    return {
      sideBarOpen,
    };
  },
});

function CollectionTemplates() {
  const { sideBarOpen } = Route.useSearch();
  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined,
  );
  const { collectionID } = Route.useParams();
  const [, copyToClipboard] = useCopyToClipboard();

  const { data, fetchNextPage, isLoading, isRefetching, error } =
    trpc.getCollectionsDetails.useInfiniteQuery(
      {
        collectionId: collectionID,
        Expert: undefined,
        Tags: undefined,
        loadAdsCreatedAfter: undefined,
        Ready: true,
        // cursor: undefined,
        limit: 20,
        sortingOptions: "Random",
      },
      {
        getNextPageParam: (lastPage) => lastPage.nextCursor,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
      },
    );

  // Ref for trigger to fetch next page
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(
    function fetchNextPageWhenElemInView() {
      if (!inView || !data) {
        return;
      }
      if (!allData) {
        return;
      }
      if (!allData.length) {
        return;
      }
      // We've fetched all the data if these are equal
      if (data.pages[0].TotalRecords <= allData?.length) {
        return;
      }
      fetchNextPage();
    },

    [inView, data, allData, fetchNextPage],
  );

  // Syncs react state with trpc state
  useEffect(
    function addFetchedAtaToReactState() {
      if (!data) return;
      setAllData(() => undefined);
      const records = [] as AirTableAdRecord[];
      for (const page of data.pages) {
        records.push(...page.ATRecords);
      }
      setAllData(() => records);
    },
    [data],
  );

  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const [columns, setColumns] = useState<number | undefined>(undefined);
  const [squareRef, { width = 0 }] = useElementSize();
  const breakpoints = {
    600: 1,
    750: 2,
    900: 3,
    1200: 4,
  };
  const gutterWidth = 18;
  useEffect(() => {
    if (!width) {
      return;
    }
    if (width > 1200) {
      // width = 1400
      // desired = (1400 / 4) - (18 * 3)

      const newWidth =
        width / breakpoints[1200] - gutterWidth * (breakpoints[1200] - 1);
      setColumns(() => 4);
      setDesiredCardWidth(() => newWidth);
      return;
    }
    if (width > 900) {
      const newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 3);
      return;
    }
    if (width > 750) {
      const newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 2);
      return;
    }
    if (width > 0) {
      const newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 1);
      return;
    }
  }, [width]);

  if (error) {
    return <ErrorDisplay />;
  }

  if (isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-screen m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <>
      <div className={""}>
        <div className={"bg-white sticky px-5 md:px-5 top-0 z-10"}>
          <div className={"pt-[1.25rem] lg:pt-[2.25rem] pb-2"}>
            <div className={"flex justify-between gap-5 items-center"}>
              <div className={"flex justify-start gap-5 items-center"}>
                <Link to={"/feeds/collections"}>
                  <Button className={"px-2.5"}>Back</Button>
                </Link>
                <Text
                  size={"lg"}
                  weight={"semibold"}
                  className={
                    "bg-black lg:bg-gradient-to-b from-[#A259FF] to-[#613599] inline-block text-transparent bg-clip-text"
                  }
                >
                  {data?.pages[0].collection.Title}
                </Text>
              </div>
              <div className={"flex gap-2 items-center"}>
                <Button
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/feeds/collections/${collectionID}`,
                    );
                    toast.success("Copied!");
                  }}
                  className={"hidden md:flex"}
                >
                  <span>Share</span>
                </Button>
                <span
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/feeds/collections/${collectionID}`,
                    );
                    toast.success("Copied!");
                  }}
                  className={
                    "flex md:hidden border rounded-md border-black p-2.5 cursor-pointer"
                  }
                >
                  <ShareIcon className={"w-[20px] h-[20px]"} />
                </span>
                <SidebarButton sideBarOpen={sideBarOpen} />
              </div>
            </div>
            <Divider className={"my-[1.25rem] hidden md:flex"} />
          </div>
        </div>
        <div ref={squareRef} className={"relative w-full px-5 pb-10"}>
          {data && (
            <>
              {data.pages[0].TotalRecords === 0 ? (
                <div className={"lg:h-96 flex justify-center items-center"}>
                  <p>No templates added to this collection</p>
                </div>
              ) : (
                allData && (
                  <div>
                    <ResponsiveMasonry
                      columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
                    >
                      <Masonry gutter={gutterWidth + "px"}>
                        {allData.map((ad) => {
                          return (
                            <AdCard
                              key={ad.atID}
                              adData={{
                                Ad: ad,
                                IsBlurred: false,
                                IsPublic: false,
                                brandName: undefined,
                                shouldInvalidateCache: false,
                                DesiredWidth: desiredCardWidth,
                              }}
                              onClickLearnMore={() => undefined}
                            />
                          );
                        })}
                      </Masonry>
                    </ResponsiveMasonry>
                  </div>
                )
              )}
            </>
          )}
          <div className={"relative"}>
            <div
              className={
                "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
              }
              ref={scrollRef}
            ></div>
          </div>
          <div className={"relative"}>
            <div
              className={
                "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
              }
              ref={scrollRef}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
}
