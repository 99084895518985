import { useEffect, useRef, useState } from "react";
import { createFileRoute, Outlet, useNavigate } from "@tanstack/react-router";
import { trpc } from "@/utils/trpc.ts";
import { ProtectedArea } from "@/components/protectedArea.tsx";
import { useOnClickOutside } from "@/hooks/useClickOutside.tsx";
import { z } from "zod";
import {
  landerFeedOrderFilter,
  orderFilter,
} from "../../../shared/airtableGet.ts";
import { Sidebar } from "@/components/templates/Sidebar";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";

type FeedsProps = {
  sideBarOpen?: boolean | undefined;
  sidebarCollapsed?: boolean | undefined;
  orderFilter?:
    | z.infer<typeof orderFilter>
    | z.infer<typeof landerFeedOrderFilter>
    | undefined;
  cacheBuster?: number | undefined;
};

export const Route = createFileRoute("/feeds")({
  component: Feeds,
  validateSearch: (search: Record<string, unknown>): FeedsProps => {
    return {
      sideBarOpen: search.sideBarOpen as boolean | undefined,
      sidebarCollapsed: search.sidebarCollapsed as boolean | undefined,
      orderFilter: search.orderFilter as
        | z.infer<typeof orderFilter>
        | undefined,
      cacheBuster: search.cacheBuster as number | undefined,
    };
  },
});

export type PermissionsData = {
  userCanAccessEverything: boolean;
  userCanAccessAds: boolean;
  userCanAccessExperts: boolean;
  ID: string;
  usersPlans: string[];
  email: string;
};

function Feeds() {
  const { sideBarOpen, sidebarCollapsed } = Route.useSearch();
  const [trialEndingPromptShow, setTrialEndingPromptShow] =
    useState<boolean>(false);

  const navigate = useNavigate();
  trpc.me.useQuery(null); // Runs because this is a great time to fetch User's data

  const { data: permissionData, isLoading: fetchingPermissions } =
    trpc.permissions.useQuery(null, {});

  const ref = useRef(null);
  const handleClickOutside = () => {
    if (sideBarOpen) {
      navigate({
        search: (old) => {
          return {
            ...old,
            sideBarOpen: false,
          };
        },
        replace: true,
        params: (old) => {
          return {
            ...old,
          };
        },
      });
    }
  };
  useOnClickOutside(ref, handleClickOutside);

  useEffect(() => {
    if (
      permissionData &&
      permissionData.usersPlans.length === 0 &&
      !permissionData.userCanAccessEverything
    ) {
      setTrialEndingPromptShow(true);
    }

    if (!sideBarOpen) {
      navigate({
        search: (old) => {
          return {
            ...old,
            sidebarCollapsed: false,
          };
        },
        replace: true,
        params: (old) => {
          return {
            ...old,
          };
        },
      });
    }
  }, [sideBarOpen, navigate]);

  useEffect(() => {
    if (
      permissionData &&
      !fetchingPermissions &&
      permissionData.usersPlans.length === 0 &&
      !permissionData.userCanAccessEverything
    ) {
      setTrialEndingPromptShow(true);
    }
  }, [permissionData]);

  return (
    <ProtectedArea>
      {trialEndingPromptShow && permissionData && !fetchingPermissions && (
        <FreeTrialEnding
          open={trialEndingPromptShow}
          onOpenChange={() => setTrialEndingPromptShow(false)}
          permissionData={permissionData}
        />
      )}

      <>
        <div className={"relative flex h-screen overflow-y-hidden"}>
          <div
            className={` absolute lg:ml-0 lg:static ${sidebarCollapsed ? "lg:w-[5rem]" : "lg:w-[15.625rem]"} w-newsidebar flex-sidebar shrink-0 z-30 transition-all duration-500 ${sideBarOpen ? "ml-0" : "-ml-newsidebar"}`}
          >
            <div ref={ref}>
              <Sidebar
                sidebarCollapsed={sidebarCollapsed}
                isPublicView={false}
              />
            </div>
          </div>
          <div className={"w-full flex-auto overflow-x-hidden overflow-y-auto"}>
            <div
              className={`${sidebarCollapsed ? "max-w-[2000px]" : "max-w-pagemax"}`}
            >
              {/*<button*/}
              {/*  className={`md:hidden w-auto px-16 py-2 mx-10 mt-2 text-sm font-semibold text-white rounded-full ${sidebarState ? "bg-red-500" : "bg-blue-500"}`}*/}
              {/*  onClick={toggleSidebar}*/}
              {/*>*/}
              {/*  Debug Toggle Box*/}
              {/*</button>*/}

              <Outlet />
            </div>
          </div>
        </div>
      </>
    </ProtectedArea>
  );
}
