import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import { AdCard } from "@/components/templates/AdCard";
import { z } from "zod";
import { useEffect, useState } from "react";
import { useElementSize } from "@/hooks/useElementSize.tsx";
import { useInView } from "react-intersection-observer";
import { ErrorDisplay } from "@/components/error.tsx";
import { trpc } from "@/utils/trpc.ts";
import { useNavigate } from "@tanstack/react-router";
import { Loader } from "@/components/custom-components/Loader";
import {
  AirTableAdRecord,
  AirtableGetArgs,
  AirtablePublicGetArgs,
} from "../../../../../shared/airtableGet.ts";
import FreeTrialEnding from "@/components/FreeTrialEnding.tsx";

type props = {
  Filter: z.infer<typeof AirtableGetArgs>;
};

export const AdGridView = (props: props) => {
  const [upgradeDialogOpen, setUpgradeDialogOpen] = useState<boolean>(false);

  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined,
  );
  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const [columns, setColumns] = useState<number | undefined>(undefined);
  const [squareRef, { width = 0 }] = useElementSize();
  const breakpoints = {
    600: 1,
    750: 2,
    900: 3,
    1200: 4,
  };
  const gutterWidth = 18;
  useEffect(() => {
    if (!width) {
      return;
    }
    if (width > 1200) {
      // width = 1400
      // desired = (1400 / 4) - (18 * 3)

      const newWidth =
        width / breakpoints[1200] - gutterWidth * (breakpoints[1200] - 1);
      setColumns(() => 4);
      setDesiredCardWidth(() => newWidth);
      return;
    }
    if (width > 900) {
      const newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 3);
      return;
    }
    if (width > 750) {
      const newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 2);
      return;
    }
    if (width > 0) {
      const newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 1);
      return;
    }
  }, [width]);

  // There's some shenanigans going on with the query here.
  // We need to invalidate this query when the posts get liked, but if we do that when the filter is random,
  // The page will refresh every time a post is liked.
  //
  // So the solution is to have a different rpc for liked posts vs regular filters
  const queryToCall = props.Filter.OnlyLiked ? trpc.likedPosts : trpc.posts;
  const { data, fetchNextPage, isError, isLoading, isRefetching } =
    queryToCall.useInfiniteQuery(props.Filter, {
      getNextPageParam: (lastPage) => lastPage.nextCursor,
      refetchOnMount: false, // Having these false means random queries don't reset on window focus
      refetchOnWindowFocus: false,
    });

  const { data: permissionData } = trpc.permissions.useQuery(null, {
    refetchOnWindowFocus: false,
  });

  // Ref for trigger to fetch next page
  const { ref: scrollRef, inView } = useInView({
    threshold: 0,
    trackVisibility: true,
    delay: 100,
    initialInView: false,
  });

  useEffect(
    function fetchNextPageWhenElemInView() {
      if (!inView || !data) {
        return;
      }
      if (!allData) {
        return;
      }
      if (!allData.length) {
        return;
      }
      // We've fetched all the data if these are equal
      if (data.pages[0].TotalRecords <= allData?.length) {
        return;
      }
      fetchNextPage();
    },
    [inView, data, fetchNextPage],
  );

  // Syncs react state with trpc state
  useEffect(
    function addFetchedAtaToReactState() {
      if (!data) return;
      setAllData(() => undefined);
      const records = [] as AirTableAdRecord[];
      for (const page of data.pages) {
        records.push(...page.ATRecords);
      }
      setAllData(() => records);
    },
    [data],
  );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!allData || isLoading || isRefetching) {
    return (
      <div
        className={"flex justify-center items-center w-full h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div className={"mt-5 lg:px-5"}>
      {upgradeDialogOpen && permissionData && (
        <FreeTrialEnding
          open={upgradeDialogOpen}
          onOpenChange={() => setUpgradeDialogOpen(false)}
          permissionData={permissionData}
        />
      )}
      {data && data.pages.length > 0 && data.pages[0].hasAccess === false && (
        <div className={"px-5 md:px-0 mb-4"}>
          <div
            className={"bg-gray-100 py-4 rounded-lg font-bold max-w-fit px-4"}
          >
            <p>
              These are just 25 of the 1,000+ creatives available.{" "}
              <span
                onClick={() => setUpgradeDialogOpen(true)}
                className={"text-blue-500 underline cursor-pointer"}
              >
                Upgrade your account to see them all.
              </span>
            </p>
          </div>
        </div>
      )}
      <div ref={squareRef} className={"relative w-full px-5 lg:px-0 pb-10"}>
        {allData && (
          <div>
            <ResponsiveMasonry
              columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
            >
              <Masonry gutter={gutterWidth + "px"}>
                {allData.map((ad) => {
                  return (
                    <AdCard
                      key={ad.atID}
                      adData={{
                        Ad: ad,
                        IsBlurred: false,
                        IsPublic: data?.pages[0].hasAccess === false,
                        brandName: undefined,
                        shouldInvalidateCache: !props.Filter.OnlyLiked,
                        DesiredWidth: desiredCardWidth,
                      }}
                      onClickLearnMore={() => undefined}
                    />
                  );
                })}
              </Masonry>
            </ResponsiveMasonry>
          </div>
        )}
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>{" "}
        <div className={"relative"}>
          <div
            className={
              "absolute w-[10px] h-[1500px] transform translate-y-[-1500px]" // Having the height be 1500px helps when the masonry grid has one column longer than another
            }
            ref={scrollRef}
          ></div>
        </div>
      </div>
    </div>
  );
};

type publicProps = z.infer<typeof AirtablePublicGetArgs>;

export const AdsGridViewPublic = (props: publicProps) => {
  // If a user is logged in, they don't need to see this screen
  const { data: authUser, isLoading: authIsLoading } = trpc.me.useQuery(null);

  const navigate = useNavigate();

  if (authUser) {
    navigate({
      to: "/feeds/templates/$adID",
      params: {
        adID: props.adID,
      },
    });
  }
  const [allData, setAllData] = useState<AirTableAdRecord[] | undefined>(
    undefined,
  );

  const [desiredCardWidth, setDesiredCardWidth] = useState<number>(0);

  // Columns needs to be controlled by the width of the container.
  // So we set the width of each masonry column to a fraction of the container width
  const [columns, setColumns] = useState<number | undefined>(undefined);
  const [squareRef, { width = 0 }] = useElementSize();
  const breakpoints = {
    600: 1,
    750: 2,
    900: 3,
    1200: 4,
  };
  const gutterWidth = 18;
  useEffect(() => {
    if (!width) {
      return;
    }
    if (width > 1200) {
      // width = 1400
      // desired = (1400 / 4) - (18 * 3)

      const newWidth =
        width / breakpoints[1200] - gutterWidth * (breakpoints[1200] - 1);
      setColumns(() => 4);
      setDesiredCardWidth(() => newWidth);
      return;
    }
    if (width > 900) {
      const newWidth =
        width / breakpoints[900] - gutterWidth * (breakpoints[900] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 3);
      return;
    }
    if (width > 750) {
      const newWidth =
        width / breakpoints[750] - gutterWidth * (breakpoints[750] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 2);
      return;
    }
    if (width > 0) {
      const newWidth =
        width / breakpoints[600] - gutterWidth * (breakpoints[600] - 1);
      setDesiredCardWidth(() => newWidth);
      setColumns(() => 1);
      return;
    }
  }, [width]);

  // There's some shenanigans going on with the query here.
  // We need to invalidate this query when the posts get liked, but if we do that when the filter is random,
  // The page will refresh every time a post is liked.
  //
  // So the solution is to have a different rpc for liked posts vs regular filters
  const { data, isError, isLoading, isRefetching } =
    trpc.publicPostByID.useQuery({
      adID: props.adID,
      cacheBuster: props.cacheBuster,
    });

  // Syncs react state with trpc state
  useEffect(
    function addFetchedAtaToReactState() {
      if (!data) return;
      setAllData(() => undefined);
      const records = [] as AirTableAdRecord[];
      for (const page of data) {
        records.push(page);
      }
      setAllData(() => records);
    },
    [data],
  );

  if (isError) {
    return (
      <div className={"px-10"}>
        <ErrorDisplay />
      </div>
    );
  }

  // isRefetching is particularly important to have here because the random filter will have different results
  // that shouldn't be cached or reused
  if (!allData || isLoading || isRefetching || authIsLoading) {
    return (
      <div
        className={"flex justify-center items-center w-full h-[70vh] m-auto"}
      >
        <Loader />
      </div>
    );
  }

  return (
    <div ref={squareRef} className={"relative w-full px-5 md:px-10 pb-10"}>
      {allData && (
        <div>
          <ResponsiveMasonry
            columnsCountBreakPoints={columns ? { 0: columns } : {}} // Columns is determined by the width of the container
          >
            <Masonry gutter={gutterWidth + "px"}>
              {allData.map((ad, index) => {
                return (
                  <AdCard
                    adData={{
                      IsPublic: true,
                      IsBlurred: index !== 0,
                      DesiredWidth: desiredCardWidth,
                      shouldInvalidateCache: false,
                      brandName: undefined,
                      avatar: undefined,
                      Ad: ad,
                    }}
                    key={ad.atID}
                  />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </div>
      )}
    </div>
  );
};
