import {
  Button,
  Checkbox,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
} from "@/components/custom-components";
import { AdHeader } from "@/components/custom-components/AdHeader";
import {
  ComponentPropsWithoutRef,
  forwardRef,
  SyntheticEvent,
  useEffect,
  useState,
} from "react";
import { Divider } from "@/components/custom-components/Divider";
import { useCopyToClipboard } from "@/hooks/useCopyToClipboard.tsx";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip.tsx";
import { ShareIcon } from "lucide-react";
import { trpc } from "@/utils/trpc.ts";
import { CanvaLogo, FigmaLogo } from "@/assets/template-logo.tsx";
import { Ad } from "@/components/templates/DiscoverGridView";
// import { useInView } from "react-intersection-observer";
import { copyToClipboard } from "@/components/copyToClipboard.tsx";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog.tsx";
import { AdPublishStatus } from "@/components/custom-components/AdPublishStatus";
import { DotsLoader, Loader } from "@/components/custom-components/Loader";
import { toast } from "sonner";
import { AirTableAdRecord } from "../../../../../shared/airtableGet.ts";
import { PlusIcon } from "@radix-ui/react-icons";
import { IconButton } from "@/components/custom-components/IconButton";
import { BoardDialog, BoardProp } from "@/components/templates/Sidebar";
import { useInView } from "react-intersection-observer";

export interface AdDataProps {
  brandName?: string;
  avatar?: string;
  Ad: AirTableAdRecord;
  IsPublic: boolean; // If the ad is being viewed from a public user
  IsBlurred: boolean; // If the ad is public, all but the requested one should be blurred
  DesiredWidth: number;
  shouldInvalidateCache: boolean; // We don't want to perform a refetch when we're on the saved page
}

export interface AdCardProps extends ComponentPropsWithoutRef<"div"> {
  adData: AdDataProps;
  isPromo?: boolean;
  onClickLearnMore?: () => void; // for only ad promos
}

export interface DiscoverAdCardProps extends ComponentPropsWithoutRef<"div"> {
  adData: Ad;
  DesiredWidth: number;
  IsPublic: boolean;
  IsBlurred: boolean;
  onView: (postId: number) => void;
}

export const AdCard = forwardRef<HTMLDivElement, AdCardProps>(
  ({ adData, isPromo = false, onClickLearnMore, ...props }, ref) => {
    const [saveOpen, setSaveOpen] = useState<boolean>(false);

    const [height, setHeight] = useState<number | undefined>(undefined);

    const [, copyToClipboard] = useCopyToClipboard();

    // We know the desired width of the column but not the height, so we calculate that here
    useEffect(() => {
      if (!adData.Ad["Example Image"][0].height || !adData.DesiredWidth) {
        return;
      }
      const multiplier =
        adData.DesiredWidth / adData.Ad["Example Image"][0].width -
        adData.DesiredWidth;
      const newHeight = adData.Ad["Example Image"][0].height * multiplier;
      setHeight(() => newHeight);
    }, [adData.DesiredWidth]);

    if (!height) {
      return <></>;
    }

    return (
      <div
        ref={ref}
        className={` border-[0.031rem] rounded-[0.5rem] border-[#B4B4B4] bg-[#F5F5F5] break-inside-avoid-column mb-5
        ${adData.IsBlurred && "filter blur-md pointer-events-none"}
        ${isPromo ? "border-[#A259FF] border-[0.125rem]" : "border-[0.031rem] border-[#B4B4B4]"}`}
        {...props}
      >
        <div
          className={
            "py-[0.75rem] px-[0.563rem] flex justify-between items-center "
          }
        >
          <AdHeader
            brandName={adData.brandName}
            Created={adData.Ad.Created}
            avatar={adData.avatar}
            className={"w-full"}
            isPromo={isPromo}
            status={adData.Ad.Status}
          />
          <div className={"text-nowrap"}>
            {isPromo ? (
              <Button
                className={"w-fit"}
                onClick={onClickLearnMore}
                variant={"secondary"}
              >
                Learn more
              </Button>
            ) : (
              !adData.IsPublic &&
              height &&
              adData && (
                <ManuallySaveTemplateToBoard
                  open={saveOpen}
                  onOpenChange={setSaveOpen}
                  data={adData}
                  height={height}
                />
              )
            )}
          </div>
        </div>
        <div className={`px-2.5`}>
          <img
            alt={"ad display"}
            data-original-width={adData.Ad["Example Image"][0].width}
            data-original-height={adData.Ad["Example Image"][0].height}
            // style={{
            //   width: adData.DesiredWidth,
            //   height: height,
            // }}
            loading={"eager"}
            src={adData.Ad["Example Image"][0].url}
            className={"w-full rounded-md"}
          />
        </div>

        {!isPromo && (
          <div
            className={
              "flex justify-between gap-[0.9375rem] px-2 py-[0.625rem]"
            }
          >
            <GetAdTemplate Ad={adData.Ad} IsPublic={adData.IsPublic} />

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <span
                    className={
                      "hover:text-blue-900 rounded-full transition-colors"
                    }
                    onClick={() => {
                      copyToClipboard(
                        `${window.location.origin}/share/templates/${adData.Ad.atID}`,
                      );
                      toast.success("Copied!");
                    }}
                  >
                    <ShareIcon className={"w-[24px] h-[24px]"} />
                  </span>
                </TooltipTrigger>
                <TooltipContent>
                  <p>Share a public link</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        )}
      </div>
    );
  },
);

export const DiscoverAdCard = forwardRef<HTMLDivElement, DiscoverAdCardProps>(
  ({ adData, IsBlurred, IsPublic, onView, ...props }, ref) => {
    const [detailsShow, setDetailsShow] = useState<boolean>(false);
    const [adRequested, setAdRequested] = useState<boolean>(false);
    const [saveOpen, setSaveOpen] = useState<boolean>(false);

    const [, copyToClipboard] = useCopyToClipboard();

    const [imageLoaded, setImageLoaded] = useState<boolean>(false);
    const [imageRef, inView] = useInView({
      threshold: 0.5, // Trigger when 50% of the image is visible
    });
    const postId = adData.id;

    const handleImageLoad = () => {
      setImageLoaded(true);
    };

    useEffect(() => {
      // Record impression only if the image is loaded and the post is in view
      if (inView && imageLoaded) {
        onView(postId); // Notify parent component to add post ID to batch
      }
    }, [inView, imageLoaded, postId, onView]);

    const { mutateAsync: requestAdTemplate } =
      trpc.requestAdTemplateRpc.useMutation();

    const { data: adCreativeDetails, isLoading: isLoadingDetails } =
      trpc.getAdCreativeDetails.useQuery(
        { adId: adData.id },
        {
          enabled: detailsShow,
        },
      );

    const [imageHeight, setImageHeight] = useState<number | null>(null);

    const handleLoad = (
      event:
        | SyntheticEvent<HTMLImageElement, Event>
        | SyntheticEvent<HTMLVideoElement, Event>,
    ) => {
      const target = event.target as HTMLImageElement;
      const aspectRatio = target.naturalHeight / target.naturalWidth;
      const displayedWidth = target.clientWidth;
      const calculatedHeight = displayedWidth * aspectRatio;
      setImageHeight(calculatedHeight);
    };

    if (!props.DesiredWidth) {
      return <></>;
    }

    return (
      <div
        ref={ref}
        className={`border-[0.031rem] rounded-[0.5rem] border-[#B4B4B4] bg-[#F5F5F5] break-inside-avoid-column mb-5
        ${IsBlurred && "filter blur-md pointer-events-none"}`}
        {...props}
      >
        <div
          className={
            "py-[0.75rem] px-[0.563rem] flex justify-between items-center "
          }
        >
          {adData.brandId && (
            <AdHeader
              brandName={adData.brandName}
              brandId={adData.brandId}
              Created={adData.earliestView}
              avatar={adData.brandImage}
              isPromo={false}
              adRunningDays={
                adData.isActiveSinceLastScrape
                  ? adData.adRunningDays
                  : adData.inactiveTime
              }
              status={adData.isActiveSinceLastScrape ? "active" : "inactive"}
            />
          )}
          <div className={"text-nowrap"}>
            {!IsPublic && adData && (
              <ManuallySaveAdToBoard
                open={saveOpen}
                onOpenChange={setSaveOpen}
                data={adData}
              />
            )}
          </div>
        </div>
        {detailsShow ? (
          <div
            style={{ height: `${imageHeight}px` }}
            className={`bg-white px-[0.625rem] overflow-y-auto py-5`}
          >
            <div>
              <AdPublishStatus
                status={adData.isActiveSinceLastScrape ? "active" : "inactive"}
                date={adData.earliestView}
                showDate
                adRunningDays={
                  adData.isActiveSinceLastScrape
                    ? adData.adRunningDays
                    : adData.inactiveTime
                }
              />
            </div>
            <Divider className={"my-5"} />
            {isLoadingDetails ? (
              <div className={"flex justify-center items-center"}>
                <Loader />
              </div>
            ) : (
              <div>
                <div className={"my-5"}>
                  <Text className={""} as={"h3"} weight={"semibold"}>
                    Ad Copy
                  </Text>
                  <Text className={"mt-2"} as={"p"} weight={"normal"}>
                    {adCreativeDetails?.headline}
                  </Text>
                  <Text className={"mt-1"} as={"p"} weight={"normal"}>
                    {adCreativeDetails?.description}
                  </Text>
                  <Text className={"mt-1"} as={"p"} weight={"normal"}>
                    {adCreativeDetails?.text
                      ?.split("\n")
                      .map((line) => <p key={line}>{line}</p>)}
                  </Text>
                </div>
                <div className={"my-5"}>
                  {adCreativeDetails?.rating && (
                    <Text weight={"semibold"}>
                      Performance {"🔥".repeat(adCreativeDetails?.rating)}
                    </Text>
                  )}
                </div>
                <a
                  href={`${adCreativeDetails?.landingPageUrl}`}
                  target={"_blank"}
                >
                  <Button className={"w-full"} variant={"brandgradient"}>
                    View landing page
                  </Button>
                </a>
              </div>
            )}
          </div>
        ) : (
          <div className={`px-2.5`}>
            {adData.imageUrl && (
              <img
                ref={imageRef}
                className={"rounded-md w-full"}
                alt={"ad display"}
                loading={"eager"}
                src={adData.imageUrl}
                onLoad={(e) => {
                  handleLoad(e);
                  handleImageLoad();
                }}
              />
            )}
            {adData.videoUrl && (
              <video
                ref={imageRef}
                className={"rounded-md w-full"}
                muted
                loop
                controls
                src={adData.videoUrl}
                onLoad={(e: SyntheticEvent<HTMLVideoElement, Event>) => {
                  handleLoad(e);
                  handleImageLoad();
                }}
              ></video>
            )}
          </div>
        )}
        <div
          className={
            "flex justify-between gap-[0.9375rem] px-[0.75rem] py-[0.625rem]"
          }
        >
          <div className={"flex gap-5"}>
            <Button
              className={"px-[1.25rem] py-[0.625rem] flex-1"}
              variant={detailsShow ? "secondary" : "primary"}
              onClick={() => setDetailsShow(!detailsShow)}
            >
              {detailsShow ? "Close" : "Details"}
            </Button>
            <Button
              className={"px-[1.25rem] py-[0.625rem] flex-1 text-nowrap"}
              variant={"primary"}
              onClick={() => {
                setAdRequested(true);
                requestAdTemplate({
                  adId: adData.id,
                }).then(() => {
                  // utils.homeFeed.invalidate();
                  toast.success(`Ad Template has been requested!`);
                  setTimeout(() => {
                    setAdRequested(false);
                  }, 5000);
                });
              }}
            >
              {adRequested ? "Requested!" : "Get Ad Template"}
            </Button>
          </div>

          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger>
                <span
                  className={
                    "hover:text-blue-900 rounded-full transition-colors"
                  }
                  onClick={() => {
                    copyToClipboard(
                      `${window.location.origin}/share/inspiration/${adData.id}`,
                    );
                    toast.success("Copied!");
                  }}
                >
                  <ShareIcon className={"w-[24px] h-[24px]"} />
                </span>
              </TooltipTrigger>
              <TooltipContent>
                <p>Share a public link</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </div>
    );
  },
);

type templateProps = {
  Ad: AirTableAdRecord;
  IsPublic?: boolean;
};

const GetAdTemplate = (props: templateProps) => {
  const [copied, setCopied] = useState<boolean>(false);
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { isLoading, refetch } = trpc.getFigmaCopyPasteCode.useQuery(
    {
      id: props.Ad.CreativeID.toString(),
      type: "record",
    },
    {
      enabled: false, // isRequested,
    },
  );

  const handleCopyClick = async () => {
    if (props.IsPublic) {
      setDialogOpen(true);
      return;
    }

    // Attempt to copy immediately after fetching
    const success = await copyToClipboard(refetch);
    if (success) {
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);
      return () => clearTimeout(timer);
    }
  }, [copied]);

  return (
    <>
      <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
        <DialogContent className="sm:max-w-[425px]">
          <DialogHeader>
            <DialogTitle className="text-2xl">
              Want the copy-and-paste template for this ad?
            </DialogTitle>
            <DialogDescription className={"text-lg mt-4"}>
              Sign up for a free trial and get access to this ad and hundreds
              more
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <a target={"_blank"} href={"https://www.creativeos.io/#sign"}>
              <Button type="submit">Get Free Access</Button>
            </a>
          </DialogFooter>
        </DialogContent>
      </Dialog>
      <div className={"py-[0.625rem] text-nowrap"}>
        <div className={"flex items-center gap-3"}>
          <div className={"flex gap-5 items-center"}>
            {isLoading ? (
              <div>
                <DotsLoader />
              </div>
            ) : copied ? (
              <div className="flex items-center bg-white rounded-full px-3 py-3">
                <p className="text-black text-sm leading-none font-semibold">
                  CMD+V in Figma
                </p>
              </div>
            ) : (
              <div onClick={handleCopyClick}>
                <div className="cursor-pointer rounded-md bg-black px-3 py-2 text-white flex items-center gap-2">
                  <Text weight="medium">Copy:</Text>
                  <FigmaLogo />
                </div>
              </div>
            )}
            <a
              href={props.IsPublic ? "" : props.Ad["Canva Template LInk"]}
              target={"_blank"}
            >
              <CanvaLogo />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

// This is component is used for the saving airtable ad to the master saved ads
function ManuallySaveTemplateToBoard({
  open,
  onOpenChange,
  data,
  height,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: AdDataProps;
  height: number;
}) {
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);
  const [localBoardsState, setLocalBoardsState] = useState<BoardProp[]>([]);

  const { data: boards, isLoading } = trpc.getBoards.useQuery(
    {
      checkAdId: data.Ad.CreativeID,
      isTemplate: true,
    },
    {
      enabled: open,
      // onSuccess: (fetchedBoards) => {
      //   // Set the localBoardsState with the fetched boards data on success
      //   setLocalBoardsState(fetchedBoards);
      // },
    },
  );

  const { mutateAsync: removeLike } = trpc.removeLike.useMutation();
  const { mutateAsync: addLike } = trpc.likePost.useMutation();
  const { mutateAsync: addTemplateToBoard } =
    trpc.addTemplateToBoard.useMutation();
  const { mutateAsync: removeTemplateFromBoard } =
    trpc.removeTemplateFromBoard.useMutation();
  const [manuallySetLike, setManuallySetLike] = useState<undefined | boolean>(
    undefined,
  );

  const utils = trpc.useUtils();

  useEffect(() => {
    if (boards) setLocalBoardsState(boards);
  }, [boards]);

  useEffect(() => {
    if (data.Ad.Liked) {
      setManuallySetLike(true);
    } else {
      setManuallySetLike(false);
    }
  }, [data.Ad.Liked]);

  if (!height) {
    return <></>;
  }

  const handleCheckboxChange = async (
    isChecked: boolean,
    boardId: string,
    boardName: string,
  ) => {
    // Optimistically update the UI for user boards
    setLocalBoardsState((prevBoards) =>
      prevBoards.map((board) =>
        board.id === boardId ? { ...board, isInBoard: isChecked } : board,
      ),
    );

    try {
      if (isChecked) {
        await addTemplateToBoard({
          boardId,
          templateId: data.Ad.atID,
        });
        toast.success(`Added to ${boardName} board!`);
      } else {
        await removeTemplateFromBoard({
          boardId,
          adId: data.Ad.CreativeID,
        });
        toast.success(`Removed from ${boardName} board!`);
      }
    } catch (error) {
      // Revert the UI to the previous state on error
      setLocalBoardsState((prevBoards) =>
        prevBoards.map((board) =>
          board.id === boardId ? { ...board, isInBoard: !isChecked } : board,
        ),
      );
      toast.error("An error occurred while updating the board.");
      console.error(error);
    } finally {
      // Revalidate the board state
      utils.getBoards.invalidate();
    }
  };

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
          templateId={data.Ad.atID}
        />
      )}
      <PopoverTrigger>
        <>
          {manuallySetLike !== undefined && (
            <>
              {manuallySetLike ? (
                <Button
                  className={"text-[0.8125rem]"}
                  variant={"secondary"}
                  onClick={() => {
                    setManuallySetLike(false);
                    removeLike({
                      postID: data.Ad.CreativeID.toString(),
                    });
                    utils.likedPosts.invalidate();
                  }}
                >
                  {"Saved"}
                </Button>
              ) : (
                <Button
                  className={"text-[0.8125rem]"}
                  onClick={() => {
                    setManuallySetLike(true);
                    addLike({
                      postID: data.Ad.CreativeID.toString(),
                    });
                    utils.likedPosts.invalidate();
                  }}
                >
                  {"Save"}
                </Button>
              )}
            </>
          )}
        </>
      </PopoverTrigger>
      <PopoverContent className={"max-h-[25.75rem]"}>
        <div className={"flex justify-between gap-3"}>
          <Text weight={"semibold"}>Add to board</Text>
          <IconButton
            onClick={() => setCreateBoardDialogOpen(true)}
            icon={<PlusIcon className={"h-[1.5rem] w-[1.5rem] "} />}
          />
        </div>
        <Stack className={"gap-2 my-5"}>
          {/* List of boards and the ad's existing status here */}
          {isLoading ? (
            <div>
              <p>Loading boards...</p>
            </div>
          ) : (
            <div className={"max-h-[16rem] overflow-y-auto"}>
              {localBoardsState &&
                localBoardsState.map((board, idx) => (
                  <div
                    key={idx}
                    className={"flex gap-2 justify-between w-full mb-2"}
                  >
                    <label htmlFor={board.name}>{board.name}</label>
                    <Checkbox
                      id={board.name}
                      checked={board.isInBoard}
                      onCheckedChange={(isChecked) => {
                        handleCheckboxChange(
                          Boolean(isChecked),
                          board.id,
                          board.name,
                        );
                      }}
                    />
                  </div>
                ))}
            </div>
          )}
        </Stack>
        <Button
          onClick={() => onOpenChange(false)}
          className={"w-full"}
          variant={"secondary"}
        >
          Done
        </Button>
      </PopoverContent>
    </Popover>
  );
}

// This is component is used for the saving ads in db to the master saved ads

function ManuallySaveAdToBoard({
  open,
  onOpenChange,
  data,
}: {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  data: Ad;
}) {
  const [createBoardDialogOpen, setCreateBoardDialogOpen] =
    useState<boolean>(false);
  const [localBoardsState, setLocalBoardsState] = useState<BoardProp[]>([]);

  const { data: boards, isLoading } = trpc.getBoards.useQuery(
    {
      checkAdId: data.id,
    },
    {
      enabled: open,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      // onSuccess: (data) => {
      //   // Initialize local state with data from the API
      //   setLocalBoardsState(data);
      // },
    },
  );

  const utils = trpc.useUtils();

  const { mutateAsync: removeLike } = trpc.unsaveAd.useMutation();
  const { mutateAsync: addLike } = trpc.saveAd.useMutation();
  const { mutateAsync: addAdToBoard } = trpc.addAdToBoard.useMutation();
  const { mutateAsync: removeAdFromBoard } =
    trpc.removeAdFromBoard.useMutation();

  const [manuallySetLike, setManuallySetLike] = useState<undefined | boolean>(
    undefined,
  );

  useEffect(() => {
    if (boards) setLocalBoardsState(boards);
  }, [boards]);

  // Basic optimistic update of the like button
  useEffect(() => {
    if (data.isSaved || data.isSaved === undefined) {
      setManuallySetLike(true);
    } else {
      setManuallySetLike(false);
    }
  }, [data.isSaved]);

  const handleBoardToggle = async (boardId: string, isChecked: boolean) => {
    // Optimistically update the local state
    setLocalBoardsState((prevBoards) =>
      prevBoards.map((board) =>
        board.id === boardId ? { ...board, isInBoard: isChecked } : board,
      ),
    );

    if (isChecked) {
      // Add the ad to the board
      await addAdToBoard({
        boardId,
        adId: data.id,
      });
      toast.success(`Added to board!`);
    } else {
      // Remove the ad from the board
      await removeAdFromBoard({
        boardId,
        adId: data.id,
      });
      toast(`Ad removed from board!`);
    }

    // Invalidate the query to refetch boards after mutation
    utils.getBoards.invalidate();
  };

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      {createBoardDialogOpen && (
        <BoardDialog
          open={createBoardDialogOpen}
          onOpenChange={() => setCreateBoardDialogOpen(false)}
          adId={data.id}
        />
      )}
      <PopoverTrigger>
        <>
          {manuallySetLike !== undefined && (
            <>
              {manuallySetLike ? (
                <Button
                  className={"text-[0.8125rem]"}
                  variant={"secondary"}
                  onClick={() => {
                    setManuallySetLike(false);
                    removeLike({
                      adId: data.id,
                    }).then(() => {
                      utils.getAllSavedAds.refetch();
                    });
                  }}
                >
                  {"Saved"}
                </Button>
              ) : (
                <Button
                  className={"text-[0.8125rem]"}
                  onClick={() => {
                    setManuallySetLike(true);
                    addLike({
                      adId: data.id,
                    }).then(() => {
                      utils.getAllSavedAds.refetch();
                    });
                  }}
                >
                  {"Save"}
                </Button>
              )}
            </>
          )}
        </>
      </PopoverTrigger>
      <PopoverContent className={"max-h-[25.75rem]"} align={"end"}>
        <div className={"flex justify-between gap-3"}>
          <Text weight={"semibold"}>Add to board</Text>
          <IconButton
            onClick={() => setCreateBoardDialogOpen(true)}
            icon={<PlusIcon className={"h-[1.5rem] w-[1.5rem] "} />}
          />
        </div>
        <Stack className={"gap-2 my-5"}>
          {/* List of boards and the ad's existing status here */}
          {isLoading ? (
            <div>
              <p>Loading boards...</p>
            </div>
          ) : (
            <div className={"max-h-[16rem] overflow-y-auto"}>
              {localBoardsState &&
                localBoardsState.map((board, idx) => (
                  <div
                    key={idx}
                    className={"flex gap-2 justify-between w-full mb-2"}
                  >
                    <label htmlFor={board.name}>{board.name}</label>
                    <Checkbox
                      id={board.name}
                      checked={board.isInBoard}
                      onCheckedChange={(isChecked) =>
                        handleBoardToggle(board.id, Boolean(isChecked))
                      }
                    />
                  </div>
                ))}
            </div>
          )}
        </Stack>
        <Button
          onClick={() => onOpenChange(false)}
          className={"w-full"}
          variant={"secondary"}
        >
          Done
        </Button>
      </PopoverContent>
    </Popover>
  );
}
